const panelCount = document.querySelector('.order-form .panel-count');

if(panelCount) {

    //Bears pseudo code section
    /*
    * Take in the min and max panel values
    * Make one variable for price and one variable for price with Enova
    *
    * Make one that for watts 400 per panel might need an inital watt value for xl that is 16
    * For a pluss and minus subtract or add .4 to the watt counter
    *
    * Price per panel is 8000nok update the total price with that
    * The math for Enova is: <watts in decimal(example 4.4)> * 2000 + 7500 = sum
    * Enova can't go over 47500 nok in support
    * Subtract the enova math with the total to get updated price
    *
    * Panels cant be lower than the amount set in the form or higher than initial value + 9
    *
    * Remember to send the amount of panels selected with the form in a hidden input or something like that.
    *
    *
    *
    * */

    //inital values for max and min
    const basePriceEnova = document.querySelector('.order-form .panel-price #price-benefit ').dataset.initialBenefit;
    const minAmountPanels = panelCount.querySelector('input').min;
    const maxAmountPanels = parseInt(minAmountPanels) + 9;


    const pricePerPanel = 6000;
    const enovaMultiplier = 1250;
    const baseBenefit = 7500

    let plus = panelCount.querySelector('.plus');
    let minus = panelCount.querySelector('.minus');
    let input = panelCount.querySelector('input');
    const panelPrice = document.querySelector('.order-form .panel-price');
    let priceBenefit = panelPrice.querySelector('#price-benefit span');
    let price = panelPrice.querySelector('#price span');

    const formatter = new Intl.NumberFormat('no-NO');

    minus.addEventListener('click', function (e) {
        e.preventDefault();
        if(input.value <= minAmountPanels) {
            return

        }

            let count = parseInt(input.value) - 1;
            let wattage = count * 0.4;

            // subtract 8000 form price
            let prevPrice = parseInt(price.innerHTML.replace(' ', '').replace('&nbsp;', ''));
            let priceCalc = parseInt(prevPrice) - pricePerPanel;
            newPrice = count < 10 ? prevPrice : priceCalc;
            price.innerHTML = formatter.format(newPrice);

            // do calculation based on current price and wattage
            let priceBenefitCalc = parseInt(newPrice) - (wattage * enovaMultiplier + baseBenefit);
            priceBenefit.innerHTML = formatter.format(priceBenefitCalc);

            input.setAttribute('value', count);

            input.value = count;

            if(input.value === minAmountPanels){
                priceBenefit.innerHTML = basePriceEnova;

            }
    });

    plus.addEventListener('click', function (e) {
        e.preventDefault();

        if(input.value >= maxAmountPanels){
            return
        }

            let count = parseInt(input.value) + 1;
            let wattage = count * 0.4;

            // subtract 8000 form price
            let prevPrice = parseInt(price.innerHTML.replace(' ', '').replace('&nbsp;', ''));
            let priceCalc = parseInt(prevPrice) + pricePerPanel;
            newPrice = count < 10 ? prevPrice : priceCalc;
            price.innerHTML = formatter.format(newPrice);

            // do calculation based on current price and wattage
            let priceBenefitCalc = parseInt(newPrice) - (wattage*enovaMultiplier+baseBenefit);
            priceBenefit.innerHTML = formatter.format(priceBenefitCalc);

            input.setAttribute('value', count);

            input.value = count;



    });

}
