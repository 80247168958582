export function initMap() {
    if (!google || !google.maps) {
        return;
    }

    var maps = document.querySelectorAll('.map');

    for (var i = 0; i < maps.length; i++) {
        var mapContainer = maps[i];
        var lat = parseFloat(mapContainer.getAttribute('data-lat')) || 0;
        var lng = parseFloat(mapContainer.getAttribute('data-lng')) || 0;
        var locations = maps[i].querySelectorAll('.marker');

        if (!lat || !lng) {
            var lat = parseFloat(locations[0].getAttribute('data-lat'));
            var lng = parseFloat(locations[0].getAttribute('data-lng'));
        }

        var position = {
            lat: lat,
            lng: lng
        };

        var options = {
            center: position,
            zoom: 16,
        };

        var map = new google.maps.Map(mapContainer, options);
        var bounds = new google.maps.LatLngBounds();

        var marker, position, address;
        for (var j = 0; j < locations.length; j++) {
            position = new google.maps.LatLng(locations[j].getAttribute('data-lat'), locations[j].getAttribute('data-lng'));
            address = locations[j].getAttribute('data-address');
            marker = new google.maps.Marker({
                position: position,
                map: map
            });
            google.maps.event.addListener(marker, 'click', function () {
                window.location.href = 'https://maps.google.com/?q=' + address;
            });
            if (locations.length > 1) {
                bounds.extend(position);
            }
        }
        if (locations.length > 1) {
            map.fitBounds(bounds);
        }
    }
}
