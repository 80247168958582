import './_body-view-height'

import './_header.js';
import './_references-preview.js';
import './_image-gallery.js';
import './_list.js';
import './_dropdown.js';
import './_order-form.js';
import { initMap } from "./maps";
window.initMap = initMap;
import './_nets-payment.js';