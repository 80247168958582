const $form = $('.order-form form');
$form.submit(function(e) {
    e.preventDefault();

    const formData = $form.serializeArray();
    const $button = $form.find('input[type="submit"]');

    $button.hide();

    const data = new FormData();
    data.append('action', 'create_order');
    data.append('url', window.location.href);

    for (let i = 0; i < formData.length; i++) {
        let name = formData[i]['name'];
        let value = formData[i]['value'];
        data.append(name, value);
    }

    $form.find('.loading').show();

    $.ajax({
        url: soln_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function(error) {
            console.error(error);
        },
        complete: function(data) {
            /*const json = JSON.parse(data.responseText);
            if (json.paymentId) {
                const checkoutOptions = {
                    checkoutKey: json.checkoutKey,
                    paymentId : json.paymentId,
                    containerId : "checkout-container-div",
                    language: "nb-NO",
                    theme: {
                        buttonRadius: "5px"
                    }
                };
                const checkout = new Dibs.Checkout(checkoutOptions);
                checkout.on('payment-completed', function(response) {
                    const paymentId = response['paymentId'];

                    const paymentData = new FormData();
                    paymentData.append('action', 'update_payment_status');
                    paymentData.append('payment_id', paymentId);
                    paymentData.append('new_status', 'completed');

                    $.ajax({
                        url: soln_scripts.ajax_url,
                        type: 'POST',
                        data: paymentData,
                        cache: false,
                        processData: false,
                        contentType: false,
                        error: function (error) {
                            console.error(error);
                        },
                        complete: function (data) {
                            $("#checkout-container-div").hide();
                            $orderComplete = $form.find('.order-complete');
                            $orderComplete.show();
                            $orderComplete.removeAttr('aria-hidden');
                            $form[0].reset();
                        }
                    });
                });

                $form.find('.loading').hide();
            }*/

            $("#checkout-container-div").hide();
            $orderComplete = $form.find('.order-complete');
            $orderComplete.show();
            $orderComplete.removeAttr('aria-hidden');
            $form[0].reset();

            $form.find('.loading').hide();
        }
    });
})
