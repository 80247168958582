const breakpoint = 1200;

if(window.innerWidth < breakpoint) {
    window.addEventListener('load', (event) => {

        const button = document.querySelector(".menu-button")
        const navBar = document.querySelector("header ul")
        const header = document.querySelector("header")

        button.onclick = function toggleMenu() {
            if (navBar.matches('.show')) {
                navBar.classList.remove("anim");
                setTimeout(function() {
                    navBar.classList.remove("show");
                }, 300);
                button.classList.remove("menu-close");
                header.classList.remove('show');
            }
            else {
                navBar.classList.add("show");
                navBar.classList.add("anim");
                button.classList.add("menu-close");
                header.classList.add('show');
            }
        };
    });
}