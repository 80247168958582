const dropdowns = document.querySelectorAll('section.dropdown-section a');

if(dropdowns) {
    dropdowns.forEach((element) => {
        let parent = element.parentElement;
        element.addEventListener('click', (e) => {
            e.preventDefault();
            if(parent.classList.contains('active')) {
                parent.classList.remove('active');
            }
            else {
                parent.classList.add('active');
            }
        });
    });
}